define("@ember/string/lib/string_registry", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getStrings = getStrings;
  _exports.setStrings = setStrings;
  // STATE within a module is frowned upon, this exists
  // to support Ember.STRINGS but shield ember internals from this legacy global
  // API.
  var STRINGS = {};
  function setStrings(strings) {
    deprecateEmberStrings();
    STRINGS = strings;
  }
  function getStrings() {
    deprecateEmberStrings();
    return STRINGS;
  }
  function deprecateEmberStrings() {
    (true && !(false) && (0, _debug.deprecate)('Ember.STRINGS is deprecated. It is no longer used by Ember.', false, {
      id: 'ember-strings',
      for: 'ember-source',
      since: {
        available: '4.10',
        enabled: '4.10.'
      },
      until: '5.0.0'
    }));
  }
});
