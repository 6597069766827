define("ember-user-activity/services/-private/fastboot-aware-event-manager", ["exports", "ember-user-activity/services/-private/event-manager"], function (_exports, _eventManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FastBootAwareEventManagerService extends _eventManager.default {
    get _isFastBoot() {
      return typeof FastBoot !== 'undefined';
    }
  }
  _exports.default = FastBootAwareEventManagerService;
});