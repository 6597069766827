define('ember-dompurify/index', ['exports', 'dompurify', 'ember-dompurify/hook'], function (exports, _dompurify, _hook) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dompurify.default;
    }
  });
  Object.defineProperty(exports, 'Hook', {
    enumerable: true,
    get: function () {
      return _hook.default;
    }
  });
});